import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { refreshMenu } from '../../redux/refreshMenu';
import { getCookies } from '../../hooks/useCookies';
import pagenotfoundBg from '../../assets/permissiondenied.png';
import styles from './permission.module.scss';
import envValues from '../../enviornment';

function PermissionDenied() {
  const navigate = useNavigate();
  const user = `${getCookies('USERROLE')}`;
  const token = `Bearer ${getCookies('Token')}`;
  const dispatch = useDispatch();
  const [userMenus, setUserMenus] = useState([]);
  const onSubmit = () => {
    dispatch(refreshMenu(user)).then(() => {
      if (userMenus.length > 0) navigate(userMenus[0]?.urlPath);
      else navigate('/');
    });
  };
  useEffect(() => {
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/notifications/getUserMenu`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setUserMenus(res.data.data.userMenu);
        }
      })
      .catch((err) => err);
  }, []);
  return (
    <>
      {/* <div className={styles[bodyStyle]} id={styles.notfoundbg}> */}
      <div className={styles.centerbox}>
        <div className={styles.imgdiv}>
          <Image src={pagenotfoundBg} alt="404image" className={styles.pagenotimage} />
        </div>
        <div>
          <h2 className={styles.pagenotfoundhead}>Access Denied</h2>
          <p className={styles.pagenotfoundpara}>
            We are sorry, the page is Un Authorized.
            <br />
            Please click to refresh the permission
          </p>
        </div>
        <div className={styles.btnsection}>
          <button
            type="button"
            onClick={() => {
              onSubmit();
            }}
            className={styles.gohomebtn}
          >
            Refresh
          </button>
        </div>
      </div>
      {/* </div> */}
      {/* <h1>Permission Denied!</h1> */}
      {/* <input
        type="button"
        value="Refresh"
        onClick={() => {
          onSubmit();
        }}
      /> */}
    </>
  );
}

export default PermissionDenied;
